import React, { useEffect } from "react";
import Layout from "../../components/_App/layout";
import SEO from "../../components/_App/seo";
import Navbar from "../../components/_App/NavbarStyleFour";
import Footer from "../../components/_App/Footer";
import PageBanner from "../../components/Common/PageBanner";
import * as Icon from "react-feather";
import { Link } from "gatsby";
import BlogSidebar from "../../components/Blog/BlogSidebar";
import virtual_lesson from "../../assets/images/blog-image/virtual_lesson.jpg";
import vr from "../../assets/images/blog-image/vr.jpg";
import { CodeBlock, vs2015 } from "react-code-blocks";
import Blog from "../../assets/json/blog.json";

function MyCoolCodeBlock({ code, language, showLineNumbers }) {
    return (
        <CodeBlock
            text={code}
            language={language}
            showLineNumbers={showLineNumbers}
            theme={vs2015}
        />
    );
}

const articleData = {
    id: 33,
    title: "Ethereum con React:",
    desc: "Come Creare una Semplice App",
    img: "/blog-image/ethereact.png",
    page: "blog/react-fetching",
    data: "25 Ott 2023",
    author: "Staff",
};

const BlogDetails = () => {
    const [isMobile, setMobile] = React.useState(false);

    const handleResize = () => {
        if (window.innerWidth < 1275) {
            setMobile(true);
        } else {
            setMobile(false);
        }
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <Layout>
            <SEO
                title={articleData.title}
                meta={[
                    {
                        name: `description`,
                        content:
                            "Il mondo delle tecnologie blockchain è sempre in fermento, e Ethereum è al centro di questa rivoluzione.",
                    },
                    {
                        property: `og:title`,
                        content: articleData.title + "Appius, software house",
                    },
                ]}
            />

            <Navbar />

            <PageBanner pageTitle={articleData.title} />

            <div className="blog-details-area ptb-80">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="blog-details-desc">
                                <div className="article-image">
                                    <img
                                        src={
                                            require("../../assets/images" +
                                                articleData.img).default
                                        }
                                        alt="image"
                                    />
                                </div>

                                <div className="article-content">
                                    <div className="entry-meta">
                                        <ul>
                                            <li>
                                                <Icon.Clock />{" "}
                                                {articleData.data}
                                            </li>
                                            <li>
                                                <Icon.User />{" "}
                                                <Link to="#">
                                                    {articleData.author}
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>

                                    <p>
                                        Il mondo delle tecnologie blockchain è
                                        sempre in fermento, e Ethereum è al
                                        centro di questa rivoluzione. La
                                        combinazione di Ethereum con React, una
                                        delle librerie JavaScript più popolari
                                        per lo sviluppo web, offre opportunità
                                        entusiasmanti per le aziende di ogni
                                        settore. In questo articolo, esploreremo
                                        come creare una semplice app Ethereum
                                        utilizzando React e come questa
                                        combinazione può essere un valore
                                        aggiunto per il tuo progetto.
                                    </p>

                                    <h3>Perché Ethereum e React?</h3>

                                    <p>
                                        Ethereum è una piattaforma di blockchain
                                        che consente agli sviluppatori di creare
                                        applicazioni decentralizzate (DApps)
                                        utilizzando gli Smart Contract. Si
                                        tratta di protocolli computerizzati che
                                        facilitano, verificano o fanno
                                        rispettare l'esecuzione di accordi senza
                                        la necessità di intermediari. Ethereum
                                        offre un ambiente sicuro e trasparente
                                        per lo sviluppo di applicazioni
                                        decentralizzate, che possono essere
                                        utilizzate in una vasta gamma di
                                        settori, tra cui finanza, logistica,
                                        arte digitale e molto altro.
                                    </p>
                                    <p>
                                        React, d'altra parte, è una libreria
                                        JavaScript per la creazione di
                                        interfacce utente reattive e dinamiche.
                                        È ampiamente utilizzato nello sviluppo
                                        web moderno ed è noto per la sua
                                        facilità di utilizzo e scalabilità. La
                                        combinazione di React con Ethereum
                                        consente agli sviluppatori di creare
                                        DApps user-friendly e coinvolgenti,
                                        portando il mondo della blockchain a una
                                        vasta gamma di utenti.
                                    </p>
                                    <h3>
                                        Creare una Semplice App Ethereum con
                                        React
                                    </h3>
                                    <p>
                                        Per dimostrare come Ethereum e React
                                        possano essere utilizzati insieme,
                                        creeremo una semplice app che consente
                                        agli utenti di inviare e ricevere Ether
                                        (la criptovaluta di Ethereum). Questa
                                        app funzionerà come un portafoglio
                                        digitale di base e dimostrerà i principi
                                        fondamentali della connessione a una
                                        blockchain Ethereum utilizzando React.
                                    </p>
                                    <ol>
                                        <li>
                                            <h5>
                                                Configurare l'Ambiente di
                                                Sviluppo
                                            </h5>
                                            <p>
                                                Prima di iniziare, assicurati di
                                                avere Node.js e npm (Node
                                                Package Manager) installati sul
                                                tuo computer. Puoi verificarlo
                                                eseguendo i comandi node{" "}
                                                <b>-v</b> e <b>npm -v</b> nel
                                                tuo terminale
                                            </p>
                                            <p>
                                                Successivamente, crea una nuova
                                                directory per il tuo progetto e
                                                naviga al suo interno:
                                            </p>
                                            <MyCoolCodeBlock
                                                code={`mkdir react-ethereum-app
cd react-ethereum-app`}
                                                language="javascript"
                                                showLineNumbers={true}
                                            />
                                            <p className="pt-3">
                                                Inizializza un nuovo progetto
                                                React utilizzando Create React
                                                App:
                                            </p>
                                            <MyCoolCodeBlock
                                                code={`npx create-react-app .`}
                                                language="javascript"
                                                showLineNumbers={true}
                                            />
                                        </li>

                                        <br />
                                        <br />
                                        {isMobile ? (
                                            <>
                                                <div className="d-flex justify-content-center">
                                                    <Link
                                                        to="/l-outsourcing-con-appius"
                                                        className="btn btn-primary"
                                                    >
                                                        Hai bisogno di aiuto per
                                                        il tuo progetto? <br />
                                                        <span
                                                            style={{
                                                                fontSize: 20,
                                                            }}
                                                        >
                                                            Clicca qui
                                                        </span>
                                                    </Link>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className="d-flex justify-content-center">
                                                    <Link
                                                        to="/l-outsourcing-con-appius"
                                                        className="btn btn-primary"
                                                    >
                                                        Hai bisogno di aiuto per
                                                        il tuo progetto? Clicca
                                                        qui
                                                    </Link>
                                                </div>
                                            </>
                                        )}
                                        <br />
                                        <li>
                                            <h5>
                                                Connettersi a una Rete Ethereum
                                            </h5>
                                            <p>
                                                Per connetterti a una rete
                                                Ethereum, avrai bisogno di un
                                                provider Web3. Il provider Web3
                                                consente alla tua app React di
                                                comunicare con la blockchain
                                                Ethereum. Puoi utilizzare
                                                MetaMask, una delle estensioni
                                                più popolari per i browser, come
                                                provider Web3 per il tuo
                                                ambiente di sviluppo. Assicurati
                                                di aver installato MetaMask nel
                                                tuo browser.
                                            </p>
                                            <p>
                                                Dopo aver configurato MetaMask
                                                con un account e una rete di
                                                test, puoi utilizzare la sua
                                                chiave privata per firmare
                                                transazioni nella tua app.
                                            </p>
                                        </li>
                                        <li>
                                            <h5>Creare un Contratto Smart</h5>
                                            <p>
                                                Per questa app, creeremo un
                                                contratto smart Ethereum molto
                                                semplice che consentirà agli
                                                utenti di inviare e ricevere
                                                Ether. Utilizzeremo il
                                                linguaggio di programmazione
                                                Solidity per scrivere il
                                                contratto.
                                            </p>
                                            <p>
                                                Ecco un esempio di contratto
                                                smart Ethereum di base:
                                            </p>
                                            <MyCoolCodeBlock
                                                code={`// SimpleWallet.sol
pragma solidity ^0.8.0;

contract SimpleWallet {
    address public owner;

    constructor() {
        owner = msg.sender;
    }

    function deposit() public payable {
    }

    function withdraw(uint256 amount) public {
        require(msg.sender == owner, "Only owner can withdraw");
        require(address(this).balance >= amount, "Insufficient balance");
        payable(owner).transfer(amount);
    }
}`}
                                                language="javascript"
                                                showLineNumbers={true}
                                            />
                                            <p className="pt-3">
                                                Questo contratto smart consente
                                                agli utenti di depositare Ether
                                                nel contratto e al proprietario
                                                di prelevare fondi. Puoi
                                                scrivere e compilare questo
                                                contratto utilizzando lo
                                                strumento Remix.
                                            </p>
                                        </li>
                                        <li>
                                            <h5>
                                                Creare l'Interfaccia Utente
                                                React
                                            </h5>
                                            <p>
                                                Ora creiamo l'interfaccia utente
                                                React per interagire con il
                                                nostro contratto smart Ethereum.
                                                Utilizzeremo il provider Web3 di
                                                MetaMask per gestire la
                                                comunicazione con la blockchain.
                                            </p>
                                            <p>
                                                Ecco un esempio di come potrebbe
                                                apparire il nostro componente
                                                React per l'interfaccia utente:
                                            </p>
                                            <MyCoolCodeBlock
                                                code={`// Wallet.js
import React, { useState, useEffect } from 'react';
import Web3 from 'web3';

function Wallet() {
    const [web3, setWeb3] = useState(null);
    const [account, setAccount] = useState('');
    const [balance, setBalance] = useState(0);

    useEffect(() => {
        async function loadWeb3() {
            if (window.ethereum) {
                const web3 = new Web3(window.ethereum);
                await window.ethereum.enable();
                setWeb3(web3);

                const accounts = await web3.eth.getAccounts();
                setAccount(accounts[0]);

                const ethBalance = await web3.eth.getBalance(accounts[0]);
                setBalance(ethBalance);
            }
        }

        loadWeb3();
    }, []);

    async function handleDeposit(amount) {
        if (web3) {
            const weiAmount = web3.utils.toWei(amount, 'ether');
            await web3.eth.sendTransaction({
                to: 'CONTRACT_ADDRESS',
                from: account,
                value: weiAmount,
            });
        }
    }

    async function handleWithdraw(amount) {
        if (web3) {
            const weiAmount = web3.utils.toWei(amount, 'ether');
            // Esegui la funzione di prelievo del contratto smart
        }
    }

    return (
        <div>
            <h1>Il tuo Portafoglio Ethereum</h1>
            <p>Indirizzo: {account}</p>
            <p>Saldo: {web3 ? web3.utils.fromWei(balance, 'ether') : 'Connettiti a MetaMask'}</p>
            <input type="text" placeholder="Importo in Ether" />
            <button onClick={() => handleDeposit(1)}>Deposita</button>
            <button onClick={() => handleWithdraw(1)}>Prelievo</button>
        </div>
    );
}

export default Wallet;`}
                                                language="javascript"
                                                showLineNumbers={true}
                                            />
                                            <p className="pt-3">
                                                Questo componente React utilizza
                                                il provider Web3 di MetaMask per
                                                connettersi a una rete Ethereum
                                                e interagire con il contratto
                                                smart. Gli utenti possono
                                                depositare e prelevare Ether
                                                utilizzando l'interfaccia
                                                utente.
                                            </p>
                                        </li>
                                        <li>
                                            <h5>Testare l'App</h5>
                                            <p>
                                                Esegui la tua app React
                                                utilizzando il seguente comando:
                                            </p>
                                            <MyCoolCodeBlock
                                                code={`npm start`}
                                                language="javascript"
                                                showLineNumbers={true}
                                            />
                                            <p className="pt-3">
                                                La tua app dovrebbe ora essere
                                                accessibile nel tuo browser
                                                all'indirizzo{" "}
                                                <a href="http://localhost:3000">
                                                    http://localhost:3000
                                                </a>
                                                .
                                            </p>
                                        </li>
                                    </ol>
                                    <p>
                                        Come abbiamo visto, creare una semplice
                                        app Ethereum con React è un modo
                                        eccitante per esplorare le possibilità
                                        offerte dalle tecnologie blockchain.
                                        Questa combinazione consente alle
                                        aziende di sviluppare DApps coinvolgenti
                                        e user-friendly per una vasta gamma di
                                        settori.
                                    </p>
                                    <p>
                                        Il nostro team di sviluppatori è pronto
                                        ad assisterti nell'integrazione di
                                        Ethereum nelle tue applicazioni o nella
                                        creazione di nuove DApps su misura per
                                        le tue esigenze. Contattaci oggi stesso
                                        per iniziare a esplorare le potenzialità
                                        di questa tecnologia rivoluzionaria e
                                        scoprire come possiamo essere il tuo
                                        partner affidabile per lo sviluppo. Buon
                                        coding a tutti.
                                    </p>
                                    <br />
                                    <br />
                                    {isMobile ? (
                                        <>
                                            <div className="d-flex justify-content-center">
                                                <Link
                                                    to="/l-outsourcing-con-appius"
                                                    className="btn btn-primary"
                                                >
                                                    Hai bisogno di aiuto per il
                                                    tuo progetto? <br />
                                                    <span
                                                        style={{ fontSize: 20 }}
                                                    >
                                                        Clicca qui
                                                    </span>
                                                </Link>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="d-flex justify-content-center">
                                                <Link
                                                    to="/l-outsourcing-con-appius"
                                                    className="btn btn-primary"
                                                >
                                                    Hai bisogno di aiuto per il
                                                    tuo progetto? Clicca qui
                                                </Link>
                                            </div>
                                        </>
                                    )}
                                    <br />
                                </div>

                                <div
                                    style={{ marginTop: 30 }}
                                    className="startp-post-navigation"
                                >
                                    {Blog.articles
                                        .filter((a) => a.id != articleData.id)
                                        .slice(0, 2)
                                        .map((article) => {
                                            return (
                                                <div className="prev-link-wrapper">
                                                    <div className="info-prev-link-wrapper">
                                                        <Link
                                                            to={
                                                                "/" +
                                                                article.page
                                                            }
                                                        >
                                                            <span className="image-prev">
                                                                <img
                                                                    src={
                                                                        require("../../assets/images" +
                                                                            article.img)
                                                                            .default
                                                                    }
                                                                    alt="image"
                                                                />
                                                                <span className="post-nav-title">
                                                                    VAI
                                                                </span>
                                                            </span>

                                                            <span className="prev-link-info-wrapper">
                                                                <span className="prev-title">
                                                                    {
                                                                        article.title
                                                                    }
                                                                </span>
                                                                <span className="meta-wrapper">
                                                                    <span className="date-post">
                                                                        {
                                                                            article.data
                                                                        }
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </Link>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-12">
                            <BlogSidebar id={articleData.id} />
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </Layout>
    );
};
export default BlogDetails;
